import Axios, { AxiosRequestConfig } from 'axios';

const {
  VUE_APP_BACKURL,
  VUE_APP_DEPLOY_URL,
  VUE_APP_DEPLOY_CONTEXT,
} = process.env;

const apiBaseUrl = VUE_APP_BACKURL;

const axios = Axios.create({
  timeout: 40000,
  baseURL: apiBaseUrl,
  headers: {},
});

const api = {
  auth: {
    google: () => {
      const loginBaseUrl = `${apiBaseUrl}/users/google`;
      const isPreview = VUE_APP_DEPLOY_CONTEXT === 'deploy-preview';
      return isPreview ? `${loginBaseUrl}?previewUrl=${VUE_APP_DEPLOY_URL}/login` : loginBaseUrl;
    },
  },
  admin: {
    funds: (options: AxiosRequestConfig) => axios.get('/funds/monitor', options),
    fundsEmails: (options: AxiosRequestConfig) => axios.get('/funds/emails', options),
    searchFundsEmails: (search: any, options: AxiosRequestConfig) => axios.post('/funds/emails/search', search, options),
    fundsEmailGetById: (id: string, options: AxiosRequestConfig) => axios.get(`/funds/emails/${id}`, options),
    fundsEmailRequeue: (id: string, options: AxiosRequestConfig) => axios.post(`/funds/emails/${id}/requeue`, options),
    getRoutines: (options: AxiosRequestConfig) => axios.get('/routines', options),
    requeueRoutine: (body: any, options: AxiosRequestConfig) => axios.post('/routines/requeue', body, options),
    deleteRoutine: (body: any, options: AxiosRequestConfig) => axios.post('/routines/delete', body, options),
    syncHealth: (options: AxiosRequestConfig) => axios.get('/reporting/synchealth', options),
    fullSyncCollection: (body: any, options: AxiosRequestConfig) => axios.post('/reporting/fullSyncCollection', body, options),
    broker: {
      queues: (options: AxiosRequestConfig) => axios.get('/broker/queues', options),
      queue: (queue: string, options: AxiosRequestConfig) => axios.get(`/broker/queues/${queue}`, options),
    },
    etl: {
      cvm: (body: any, options: AxiosRequestConfig) => axios.post('/etl/cvm/dailyinfo', body, options),
      cdi: (body: any, options: AxiosRequestConfig) => axios.post('/etl/anbima', body, options),
      anbima: (body: any, options: AxiosRequestConfig) => axios.post('/etl/anbima/indexes', body, options),
      ibov: (body: any, options: AxiosRequestConfig) => axios.post('/etl/worldtradingdata/ibov', body, options),
      sep: (body: any, options: AxiosRequestConfig) => axios.post('/etl/worldtradingdata/sep', body, options),
      up2data: {
        companiesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/companies/info', body, options),
        equitiesSharesHistory: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/shares/history', body, options),
        equitiesSharesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/shares/info', body, options),
        equitiesOptionsInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/options/info', body, options),
        equitiesOptionsRefPrice: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/options/refPrice', body, options),
        equitiesFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/futures/info', body, options),
        equitiesFuturesSettlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/equities/futures/settlementInfo', body, options),
        indexesFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/indexes/futures/info', body, options),
        indexesFuturesSettlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/indexes/futures/settlementInfo', body, options),
        commoditiesFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/commodities/futures/info', body, options),
        commoditiesFuturesSettlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/commodities/futures/settlementInfo', body, options),
        fixedIncomeFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/fixedIncome/futures/info', body, options),
        fixedIncomeFuturesSettlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/fixedIncome/futures/settlementInfo', body, options),
        interestRateFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/interestRate/futures/info', body, options),
        interestRateFuturesSettlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/interestRate/futures/settlementInfo', body, options),
        currencyFuturesInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/currency/futures/info', body, options),
        currencyFuturesSetlementInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/currency/futures/setlementInfo', body, options),
        indexesOptionsInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/indexes/options/info', body, options),
        interestRateOptionsInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/interestRate/options/info', body, options),
        currencyOptionsInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/currency/options/info', body, options),
        commoditiesOptionsInfo: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/commodities/options/info', body, options),
        indexesHistory: (body: any, options: AxiosRequestConfig) => axios.post('/etl/up2data/indexes/history', body, options),
      },
    },
    data: {
      funds: (options: AxiosRequestConfig) => axios.get('/funds/health', options),
      fundsList: (options: AxiosRequestConfig) => axios.get('/funds/health/list', options),
      indexes: (options: AxiosRequestConfig) => axios.get('/indexes/health', options),
      indexesList: (options: AxiosRequestConfig) => axios.get('/indexes/health/list', options),
    },

    scheduler: {
      getJobs: (options: AxiosRequestConfig) => axios.get('/scheduler/jobs', options),
      createJob: (data: any, options: AxiosRequestConfig) => axios.post('/scheduler/jobs', data, options),
      getJobDetails: (jobId: string, options: AxiosRequestConfig) => axios.get(`/scheduler/jobs/${jobId}`, options),
      updateJob: (jobId: string, data: any, options: AxiosRequestConfig) => axios.patch(`/scheduler/jobs/${jobId}`, data, options),
      deleteJob: (jobId: string, options: AxiosRequestConfig) => axios.delete(`/scheduler/jobs/${jobId}`, options),
      triggerJob: (jobId: string, options: AxiosRequestConfig) => axios.post(`/scheduler/jobs/${jobId}/run`, null, options),

      getJobHistory: (jobId: string, options: AxiosRequestConfig) => axios.get(`/scheduler/jobs/${jobId}/history`, options),
    },
  },
  administrators: {
    list: (options: AxiosRequestConfig) => axios.get('/administrators', options),
    byIds: (administratorIdsList: string[], options: AxiosRequestConfig) => axios.post('/administrators/read', { ids: administratorIdsList }, options),
    create: (body: any, options: AxiosRequestConfig) => axios.post('/administrators', body, options),
    search: (options?: AxiosRequestConfig) => axios.post('/administrators/search', options),
    update: (administratorId: string, body: any, options: AxiosRequestConfig) => axios.patch(`/administrators/${administratorId}`, body, options),
    remove: (administratorId: string) => axios.delete(`/administrators/${administratorId}`),
  },
  companies: {
    list: (options: AxiosRequestConfig) => axios.get('/companies', options),
    getById: (companyId: string) => axios.get(`/companies/${companyId}`),
  },
  shares: {
    list: (options: AxiosRequestConfig) => axios.get('/shares', options),
    search: (options?: AxiosRequestConfig) => axios.post('/shares/search', options),
    getById: (sharesId: string) => axios.get(`/shares/${sharesId}`),
    historyById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/shares/${fundId}/history`, options),
  },
  shareoptions: {
    list: (options: AxiosRequestConfig) => axios.get('/shareoptions', options),
    search: (options?: AxiosRequestConfig) => axios.post('/shareoptions/search', options),
    getById: (sharesId: string) => axios.get(`/shareoptions/${sharesId}`),
    historyById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/shareoptions/${fundId}/history`, options),
  },
  me: {
    whoami: () => axios.get('/users/whoami'),
    notifications: () => axios.patch('/users/notifications'),
    getIntegrationTokens: () => axios.get('/users/me/token'),
    generateIntegrationToken: () => axios.post('/users/me/token'),
    revokeIntegrationToken: (revokeTokenId: string) => axios.patch(`/users/me/token/${revokeTokenId}`),
    putNotification: (body: any, options: any) => axios.put('/users/me/notifications', body, options),
    deleteNotification: (options: any) => axios.delete('/users/me/notifications', options),
  },

  houseFunds: {
    getHouseFundsList: (options?: AxiosRequestConfig) => axios.get('/portfolios/housefunds', options),
    getHouseFundDetails: (houseFundId: string, options?: AxiosRequestConfig) => axios.get(`/portfolios/housefunds/${houseFundId}`, options),
    getOperations: (houseFundId: string, options?: AxiosRequestConfig) => axios.get(`/portfolios/assetsoperations/${houseFundId}/operations`, options),

    createHouseFund: (data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: '/portfolios/housefunds/',
      data,
      ...options,
    }),

    updateHouseFund: (houseFundId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'patch',
      url: `/portfolios/housefunds/${houseFundId}`,
      data,
      ...options,
    }),

    addAdmFee: (houseFundId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/housefunds/admFee/${houseFundId}`,
      data,
      ...options,
    }),

    updateAdmFee: (admFeeId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'patch',
      url: `/portfolios/housefunds/admFee/${admFeeId}`,
      data,
      ...options,
    }),

    deleteAdmFee: (admFeeId: string, options?: AxiosRequestConfig) => axios.request({
      method: 'delete',
      url: `/portfolios/housefunds/admFee/${admFeeId}`,
      ...options,
    }),

    addPerformanceFee: (houseFundId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/housefunds/performanceFee/${houseFundId}`,
      data,
      ...options,
    }),

    updatePerformanceFee: (performanceFeeId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'patch',
      url: `/portfolios/housefunds/performanceFee/${performanceFeeId}`,
      data,
      ...options,
    }),

    deletePerformanceFee: (performanceFeeId: string, options?: AxiosRequestConfig) => axios.request({
      method: 'delete',
      url: `/portfolios/housefunds/performanceFee/${performanceFeeId}`,
      ...options,
    }),

    addExternalId: (houseFundId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/housefunds/externalIds/${houseFundId}`,
      data,
      ...options,
    }),

    updateExternalId: (externalIdId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'patch',
      url: `/portfolios/housefunds/externalIds/${externalIdId}`,
      data,
      ...options,
    }),

    deleteExternalId: (externalIdId: string, options?: AxiosRequestConfig) => axios.request({
      method: 'delete',
      url: `/portfolios/housefunds/externalIds/${externalIdId}`,
      ...options,
    }),

    addCalcConfig: (houseFundId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/housefunds/calcConfig/${houseFundId}`,
      data,
      ...options,
    }),

    updateCalcConfig: (calcConfigId: string, data: any, options?: AxiosRequestConfig) => axios.request({
      method: 'patch',
      url: `/portfolios/housefunds/calcConfig/${calcConfigId}`,
      data,
      ...options,
    }),

    deleteCalcConfig: (calcConfigId: string, options?: AxiosRequestConfig) => axios.request({
      method: 'delete',
      url: `/portfolios/housefunds/calcConfig/${calcConfigId}`,
      ...options,
    }),
  },

  operations: {
    bonds: {
      getBondsOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/bondsOperations', options),
      createBondsOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/bondsOperations',
        data,
        ...options,
      }),
      updateBondsOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/bondsOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteBondsOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/bondsOperations/${operationId}`,
        ...options,
      }),
    },

    cashSettlements: {
      getCashSettlements: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/cashSettlements', options),
      getCashSettlementsStatements: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/cashSettlementsStatement', options),
      createCashSettlement: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/cashSettlements',
        data,
        ...options,
      }),
      updateCashSettlement: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/cashSettlements/${operationId}`,
        data,
        ...options,
      }),
      deleteCashSettlement: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/cashSettlements/${operationId}`,
        ...options,
      }),
    },

    funds: {
      getFundsOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/fundsOperations', options),
      getFundsOperationsStatements: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/fundsOperationsStatement', options),
      createFundsOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/fundsOperations',
        data,
        ...options,
      }),
      updateFundsOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/fundsOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteFundsOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/fundsOperations/${operationId}`,
        ...options,
      }),
    },

    futureOptions: {
      getFutureOptionsOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/futureOptionsOperations', options),
      createFutureOptionsOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/futureOptionsOperations',
        data,
        ...options,
      }),
      updateFutureOptionsOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/futureOptionsOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteFutureOptionsOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/futureOptionsOperations/${operationId}`,
        ...options,
      }),
      bulkDeleteFutureOptionsOperations: (operationArrayId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/futureOptionsOperations/${operationArrayId}/list`,
        ...options,
      }),
    },

    futures: {
      getFuturesOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/futuresOperations', options),
      createFuturesOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/futuresOperations',
        data,
        ...options,
      }),
      updateFuturesOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/futuresOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteFuturesOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/futuresOperations/${operationId}`,
        ...options,
      }),
      bulkDeleteFuturesOperations: (operationArrayId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/futuresOperations/${operationArrayId}/list`,
        ...options,
      }),
    },

    loan: {
      getLoanOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/loanOperations', options),
      createLoanOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/loanOperations',
        data,
        ...options,
      }),
      updateLoanOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/loanOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteLoanOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/loanOperations/${operationId}`,
        ...options,
      }),
    },

    repurchase: {
      getRepurchaseOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/repurchaseOperations', options),
      createRepurchaseOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/repurchaseOperations',
        data,
        ...options,
      }),
      updateRepurchaseOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/repurchaseOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteRepurchaseOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/repurchaseOperations/${operationId}`,
        ...options,
      }),
    },

    shareOptions: {
      getShareOptionsOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/shareOptionsOperations', options),
      createShareOptionsOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/shareOptionsOperations',
        data,
        ...options,
      }),
      updateShareOptionsOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/shareOptionsOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteShareOptionsOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/shareOptionsOperations/${operationId}`,
        ...options,
      }),
      bulkDeleteShareOptionsOperations: (operationArrayId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/shareOptionsOperations/${operationArrayId}/list`,
        ...options,
      }),
    },

    shares: {
      getSharesOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/assetsOperations/sharesOperations', options),
      createSharesOperation: (data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'post',
        url: '/portfolios/assetsOperations/sharesOperations',
        data,
        ...options,
      }),
      updateSharesOperation: (operationId: string, data: any, options?: AxiosRequestConfig) => axios.request({
        method: 'patch',
        url: `/portfolios/assetsOperations/sharesOperations/${operationId}`,
        data,
        ...options,
      }),
      deleteSharesOperation: (operationId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/sharesOperations/${operationId}`,
        ...options,
      }),
      bulkDeleteSharesOperations: (operationArrayId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'delete',
        url: `/portfolios/assetsOperations/sharesOperations/${operationArrayId}/list`,
        ...options,
      }),
    },
  },

  liabilities: {
    getLiabilitiesList: (options?: AxiosRequestConfig) => axios.get('/portfolios/liabilities/', options),
    getLiabilitiesDetails: (houseFundId: string, refDate: string, options?: AxiosRequestConfig) => axios.request({
      method: 'get',
      url: `/portfolios/liabilities/${houseFundId}`,
      params: { refDate },
      ...options,
    }),
  },

  quotaholders: {
    getQuotaholdersList: (options?: AxiosRequestConfig) => axios.get('/portfolios/quotaholders/', options),
    getQuotaholderById: (id: string, options?: AxiosRequestConfig) => axios.get(`/portfolios/quotaholders/${id}`, options),
    createQuotaholder: (data: any, options?: AxiosRequestConfig) => axios.post('/portfolios/quotaholders/', data, options),
    updateQuotaholder: (id: string, data: any, options?: AxiosRequestConfig) => axios.patch(`/portfolios/quotaholders/${id}`, data, options),
    deleteQuotaholder: (id: string, options?: AxiosRequestConfig) => axios.delete(`/portfolios/quotaholders/${id}`, options),
  },

  quotaholdersOperations: {
    getQuotaholdersOperations: (options?: AxiosRequestConfig) => axios.get('/portfolios/QuotaholdersOperations/', options),
    getQuotaholdersOperationsStatements: (options?: AxiosRequestConfig) => axios.get('/portfolios/QuotaholdersOperations/statement', options),
    createQuotaholdersOperation: (data: any, options?: AxiosRequestConfig) => axios.post('/portfolios/QuotaholdersOperations/', data, options),
    createQuotaholdersAmortization: (data: any, options?: AxiosRequestConfig) => axios.post('/portfolios/QuotaholdersOperations/amortization-auto', data, options),
    createQuotaholdersLiabilitiesTransfer: (data: any, options?: AxiosRequestConfig) => axios.post('/portfolios/QuotaholdersOperations/liabilities-transfer-auto', data, options),
    updateQuotaholdersOperation: (id: string, data: any, options?: AxiosRequestConfig) => axios.patch(`/portfolios/QuotaholdersOperations/${id}`, data, options),
    deleteQuotaholdersOperation: (id: string, options?: AxiosRequestConfig) => axios.delete(`/portfolios/QuotaholdersOperations/${id}`, options),
    bulkDeleteQuotaholdersOperations: (operationIds: string[], options?: AxiosRequestConfig) => axios.delete('/portfolios/QuotaholdersOperations/list', { params: { operationIds }, ...options }),
  },

  holdings: {
    getHoldingsList: (options?: AxiosRequestConfig) => axios.get('/portfolios/holdings', options),
    getHoldingsOverview: (options?: AxiosRequestConfig) => axios.get('/portfolios/holdings/overview', options),
    getHoldingsDetails: (houseFundId: string, refDate: string, options?: AxiosRequestConfig) => axios.request({
      method: 'get',
      url: `/portfolios/holdings/${houseFundId}`,
      params: { refDate },
      ...options,
    }),
    compareHoldings: (houseFundId: string, refDate: string, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: '/portfolios/holdings/checkHoldings',
      data: { houseFundId, refDate },
      ...options,
    }),
    compareHoldingsPeriod: (filters: Record<string, any>, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: '/portfolios/holdings/checkHoldingsPeriod',
      data: filters,
      ...options,
    }),
    calculatePortfolio: (houseFundId: string, refDate: string, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/calculation/calculatePortfolio/${houseFundId}`,
      params: { refDate },
      ...options,
    }),
    calculatePortfolioPeriod: (filters: Record<string, any>, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: '/portfolios/calculation/calculateperiod',
      data: filters,
      ...options,
    }),
    approveHoldings: (holdingId: string, errorList = [], options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/holdings/approve/${holdingId}`,
      data: { errorList },
      ...options,
    }),
    reproveHoldings: (holdingId: string, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: `/portfolios/holdings/reprove/${holdingId}`,
      ...options,
    }),
    importOfficialHoldings: (xmlString: string, options?: AxiosRequestConfig) => axios.request({
      method: 'post',
      url: '/portfolios/holdings/officialHoldings',
      headers: {
        'Content-Type': 'text/xml',
      },
      data: xmlString,
      ...options,
    }),
  },

  risk: {
    var: {
      getVarDetails: (refDate: string, houseFundId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'get',
        url: '/risk/var',
        params: { houseFundId, startDate: refDate, endDate: refDate },
        ...options,
      }),
    },
    stress: {
      getStressDetails: (refDate: string, houseFundId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'get',
        url: '/risk/stress',
        params: { houseFundId, startDate: refDate, endDate: refDate },
        ...options,
      }),
    },
    liquidity: {
      getRegulatorCompliance: (refDate: string, houseFundId: string, options?: AxiosRequestConfig) => axios.request({
        method: 'get',
        url: '/risk/regulatorCompliance',
        params: { houseFundId, startDate: refDate, endDate: refDate },
        ...options,
      }),
    },
  },

  returnsBoard: {
    getBoardList: (options?: AxiosRequestConfig) => axios.get('/funds/returnsBoardV3', options),
    createBoard: (data: any, options?: AxiosRequestConfig) => axios.post('/funds/returnsBoard', data, options),
    updateBoard: (boardId: string, data: any, options?: AxiosRequestConfig) => axios.patch(`/funds/returnsBoard/${boardId}`, data, options),
    deleteBoard: (boardId: string, options?: AxiosRequestConfig) => axios.delete(`/funds/returnsBoard/${boardId}`, options),
    getBoardDetails: (boardId: string, options?: AxiosRequestConfig) => axios.get(`/funds/returnsBoard/${boardId}`, options),
    getBoardResults: (boardId: string, refDate: string, options?: AxiosRequestConfig) => axios.get(`/funds/returnsBoard/results/${boardId}`, {
      params: { refDate },
      ...options,
    }),

    addBoardClass: (boardId: string, data: any, options?: AxiosRequestConfig) => axios.post(`/funds/returnsBoard/class/${boardId}`, data, options),
    updateBoardClass: (classId: string, data: any, options?: AxiosRequestConfig) => axios.patch(`/funds/returnsBoard/class/${classId}`, data, options),
    deleteBoardClass: (classId: string, options?: AxiosRequestConfig) => axios.delete(`/funds/returnsBoard/class/${classId}`, options),

    addFundToClass: (classId: string, fundId: string, options?: AxiosRequestConfig) => (
      axios.post(`/funds/returnsBoard/fund/${classId}`, { fundId }, options)
    ),
    removeFundFromClass: (fundId: string, options?: AxiosRequestConfig) => (
      axios.delete(`/funds/returnsBoard/fund/${fundId}`, options)
    ),
  },

  funds: {
    list: (options?: AxiosRequestConfig) => axios.get('/funds', options),
    overview: (options?: AxiosRequestConfig) => axios.get('/funds/overview', options),
    byId: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}`, options),
    monthly: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}/monthly`, options),
    returnsById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}/returns`, options),
    volatilityById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}/volatility`, options),
    drawdownById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}/drawdown`, options),
    returnsBoard: (options?: AxiosRequestConfig) => axios.get('/funds/returnsBoard', options),
    returnsBoardv2: (options?: AxiosRequestConfig) => axios.get('/funds/returnsBoardv2', options),
    calculateReturns: (options?: AxiosRequestConfig) => axios.post('/funds/calculateReturns', options),
    search: (options?: AxiosRequestConfig) => axios.post('/funds/search', options),
    searchv2: (data: any, options?: AxiosRequestConfig) => axios.post('/funds/search', data, options),
    getHealth: (fundId?: string, options?: AxiosRequestConfig) => axios.get(`/funds/${fundId}/health`, options),
    runHealth: (fundId?: string, body?: any, options?: AxiosRequestConfig) => axios.post(`/funds/${fundId}/health`, body, options),
    updateById: (fundId: string, data: any, options?: AxiosRequestConfig) => axios.patch(`/funds/${fundId}`, data, options),
    monitor: (options?: AxiosRequestConfig) => axios.get('/funds/monitor', options), // Admin,
    dailyReports: {
      list: (options?: AxiosRequestConfig) => axios.get('/funds/dailyReport', options),
      listFiles: (fund: string, options?: AxiosRequestConfig) => axios.get(`/funds/dailyReport/${fund}`, options),
      getFile: (fund: string, file: string) => `${apiBaseUrl}/funds/dailyReport/${fund}/${file}`,
      check: (path: string, options?: AxiosRequestConfig) => axios.get('/funds/dailyReport/check', { params: { path }, ...options }),
    },
    histories: {
      deleteById: (historyId: string, options?: AxiosRequestConfig) => axios.delete(`/funds/histories/${historyId}`, options),
      create: (data: any, options?: AxiosRequestConfig) => axios.post('/funds/histories', data, options),
    },
  },
  bonds: {
    list: (options?: AxiosRequestConfig) => axios.get('/bonds', options),
    search: (options?: AxiosRequestConfig) => axios.get('/bonds/search', options),
    byIds: (ids: string[], options?: AxiosRequestConfig) => axios.post('/bonds/read', { ids }, options),
  },
  brokers: {
    list: (options?: AxiosRequestConfig) => axios.get('/brokers', options),
    search: (query: string, options?: AxiosRequestConfig) => axios.post('/brokers/search', { query }, options),
    byIds: (ids: string[], options?: AxiosRequestConfig) => axios.post('/brokers/read', { ids }, options),
  },
  indexes: {
    list: (options?: AxiosRequestConfig) => axios.get('/indexes', options),
    byId: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/indexes/${fundId}`, options),
    returnsById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/indexes/${fundId}/returns`, options),
    historyById: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/indexes/${fundId}/history`, options),
    returnsBoard: (options?: AxiosRequestConfig) => axios.get('/indexes/returnsBoard', options),
    search: (options?: AxiosRequestConfig) => axios.post('/indexes/search', options),
    updateById: (fundId: string, options?: AxiosRequestConfig) => axios.patch(`/indexes/${fundId}`, options),
  },
  managers: {
    list: (options?: AxiosRequestConfig) => axios.get('/managers', options),
    byId: (managerId: string, options?: AxiosRequestConfig) => axios.get(`/managers/${managerId}`, options),
    search: (options?: AxiosRequestConfig) => axios.post('/managers/search', options),
    updateById: (managerId: string, options?: AxiosRequestConfig) => axios.patch(`/managers/${managerId}`, options),
  },
  users: {
    list: (options?: AxiosRequestConfig) => axios.get('/users', options),
    byId: (fundId: string, options?: AxiosRequestConfig) => axios.get(`/users/${fundId}`, options),
    byIdIntegrationTokens: (userId: string, options: AxiosRequestConfig) => axios.get(`/users/${userId}/token/`, options),
    search: (options?: AxiosRequestConfig) => axios.post('/users/search', options),
    updateById: (fundId: string, options?: AxiosRequestConfig) => axios.patch(`/users/${fundId}`, options),
    logs: {
      list: (options?: AxiosRequestConfig) => axios.get('/users/logs', options),
      listById: (userId: string, options?: AxiosRequestConfig) => axios.get(`/users/logs/${userId}`, options),
    },
  },
  quant: {
    list: (options?: AxiosRequestConfig) => axios.get('/quant/strategies', options),
    getExecutions: (strategy: string, options?: AxiosRequestConfig) => axios.get(`/quant/executions/${strategy}`, options),
    getExecutionsOverview: (options?: AxiosRequestConfig) => axios.get('/quant/executions/overview', options),
    getById: (id: string, options?: AxiosRequestConfig) => axios.get(`/quant/strategies/${id}`, options),
    getHistoryById: (id: string, options?: AxiosRequestConfig) => axios.get(`/quant/strategies/${id}/history`, options),
    runStrategy: (id: string, options?: AxiosRequestConfig) => axios.post(`/quant/strategies/${id}/run`, options),
    create: (body: string, options?: AxiosRequestConfig) => axios.post('/quant/strategies', body, options),
  },

  operator: {
    requeueHolding: (houseFundId: string, refDate: string, options?: AxiosRequestConfig) => axios.post(`/operator/btgadm/requeue/${houseFundId}`, {}, { ...options, params: { refDate } }),
  },
};

export { axios };

export default api;
