<template>
  <contextual-dialog
    v-bind="dialogOptions"

    :value="isAlertVisible"
    @input="handleVisibilityChange"
  />
</template>

<script>
import ContextualDialog from './ContextualDialog.vue';

export default {
  name: 'AppAlert',

  components: {
    ContextualDialog,
  },

  computed: {
    isAlertVisible: (vm) => vm.$store.state.alert?.isVisible,

    dialogOptions: (vm) => ({
      loading: vm.$store.state.alert?.isLoading,
      icon: vm.$store.state.alert?.icon,
      iconColor: vm.$store.state.alert?.iconColor,
      title: vm.$store.state.alert?.title,
      message: vm.$store.state.alert?.message,
      errorList: vm.$store.state.alert?.errorList,
    }),
  },

  methods: {
    handleVisibilityChange(newState) {
      if (!newState) {
        this.$store.dispatch('alert/hideAlert');
      }
    },
  },
};
</script>
