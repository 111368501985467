<template>
  <v-list-group no-action :prepend-icon="icon" v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          {{ text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-for="(child, index) of children">
      <nav-tree-item :key="index" :config="child" />
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: 'NavTreeNode',

  inheritAttrs: false,

  components: {
    // Circular components must be called this way as seen in
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    NavTreeItem: () => import('./NavTreeItem.vue'),
  },

  props: {
    text: {
      type: String,
      required: true,
    },

    children: {
      type: Array,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
