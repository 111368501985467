export interface HouseFundState {
  results: any[];
  loadingCount: number;
  lastResponseDate: Date | null;
  requestError: Error | null;
}

export default {
  results: [],

  // Request meta
  loadingCount: 0,
  lastResponseDate: null,
  requestError: null,
} as HouseFundState;
