import { ActionTree } from 'vuex';
import { BulkAlertState } from './state';

export default {
  showBulkAlert({ commit }, options = {}) {
    commit('UPDATE_BULK_ALERT_ICON', options.icon ?? 'mdi-close');
    commit('UPDATE_BULK_ALERT_ICON_COLOR', options.iconColor ?? 'error');
    commit('UPDATE_BULK_ALERT_LOADING_STATE', options.loading ?? false);

    commit('UPDATE_BULK_ALERT_TITLE', options.title ?? 'Ooops!');
    commit('UPDATE_BULK_ALERT_SUBTITLE', options.subtitle ?? 'Algo de errado aconteceu, tente novamente mais tarde.');
    commit('UPDATE_BULK_ALERT_DATA', options.data ?? []);

    commit('UPDATE_BULK_ALERT_VISIBILITY', true);
  },

  hideBulkAlert({ commit }) {
    commit('UPDATE_BULK_ALERT_VISIBILITY', false);

    commit('UPDATE_BULK_ALERT_ICON', '');
    commit('UPDATE_BULK_ALERT_ICON_COLOR', '');
    commit('UPDATE_BULK_ALERT_LOADING_STATE', false);

    commit('UPDATE_BULK_ALERT_TITLE', '');
    commit('UPDATE_BULK_ALERT_SUBTITLE', '');
    commit('UPDATE_BULK_ALERT_DATA', []);
  },
} as ActionTree<BulkAlertState, any>;
