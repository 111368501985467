import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'indexes-list',
    path: '/indexes',
    component: () => import(/* webpackChunkName: "indexes" */ '../components/indexes/IndexesList.vue'),
  },

  {
    name: 'index-details',
    path: '/indexes/:id',
    props: true,
    component: () => import(/* webpackChunkName: "indexes" */ '../components/indexes/IndexDetails.vue'),
  },
] as RouteConfig[];
