import { GetterTree } from 'vuex';
import { HouseFundState } from './state';

export default {
  isLoading: ({ loadingCount }) => loadingCount > 0,
  hasError: ({ requestError }) => requestError instanceof Error,
  hasRequested: ({ lastResponseDate }) => lastResponseDate instanceof Date,
  hasResults: ({ results }) => results.length > 0,

  activeHouseFunds: ({ results }) => results.filter((item) => !item.endDate),
} as GetterTree<HouseFundState, any>;
