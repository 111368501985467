<template>
  <div class="app-sidebar">
    <nav-tree-root nav dense :items="navItems" />
  </div>
</template>

<script>
import NavTreeRoot from '@/components/global/NavTree/NavTreeRoot.vue';

export default {
  name: 'Sidebar',

  components: {
    NavTreeRoot,
  },

  data: () => ({
    rawItems: [
      {
        subheader: true,
        text: 'Dados de Mercado',
        acl: ['admin'],
      },

      {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        to: '/dashboard',
      },

      {
        icon: 'mdi-table',
        text: 'Quadro de Retornos',
        to: '/returns',
      },

      {
        icon: 'mdi-alpha-f-circle',
        text: 'Fundos',
        children: [
          {
            icon: 'mdi-alpha-f-box',
            text: 'Fundos',
            to: '/funds',
          },

          {
            icon: 'mdi-alpha-g-circle-outline',
            text: 'Gestores',
            to: '/managers',
          },

          {
            icon: 'mdi-alpha-a-circle-outline',
            text: 'Administradoras',
            to: '/administrators',
          },
        ],
      },

      {
        icon: 'mdi-finance',
        text: 'Simulador',
        href: 'https://simulador.loyall.com.br/',
        target: '_blank',
      },

      {
        subheader: true,
        text: 'Portfolios',
      },

      {
        icon: 'mdi-home-currency-usd',
        text: 'Fundos da casa',
        to: '/housefunds',
      },

      {
        icon: 'mdi-handshake',
        text: 'Cotistas',
        to: '/quotaholders',
      },

      {
        icon: 'mdi-timeline-text-outline',
        text: 'Posições de ativos',
        to: '/holdings',
      },

      {
        icon: 'mdi-timeline-text',
        text: 'Posições de passivo',
        to: '/liabilities',
      },

      {
        icon: 'mdi-cogs',
        text: 'Operações',
        children: [
          {
            text: 'Extrato C/C',
            to: '/operations/cashsettlementsstatements',
          },

          {
            text: 'Ações',
            to: '/operations/shares',
          },

          {
            text: 'Opções de Ação',
            to: '/operations/shareoptions',
          },

          {
            text: 'Futuros',
            to: '/operations/futures',
          },

          {
            text: 'Opções de Futuro',
            to: '/operations/futureoptions',
          },

          {
            text: 'Renda Fixa',
            to: '/operations/bonds',
          },

          {
            text: 'Compromissadas',
            to: '/operations/repurchase',
          },

          {
            text: 'Fundos',
            to: '/operations/funds',
          },

          {
            text: 'BTC',
            to: '/operations/loan',
          },

          {
            text: 'Lançamentos de Caixa',
            to: '/operations/cashsettlements',
          },

          {
            text: 'Operações de Cotistas',
            to: '/quotaholdersoperations',
          },
        ],
      },

      {
        subheader: true,
        text: 'Admin',
        acl: ['admin'],
      },

      {
        icon: 'mdi-account-multiple',
        text: 'Usuários',
        to: '/admin/users',
        acl: ['admin'],
      },

      {
        icon: 'mdi-math-log',
        text: 'Logs de Usuários',
        to: '/admin/users-logs',
        acl: ['admin'],
      },

      {
        icon: 'mdi-calendar-refresh',
        text: 'Rotinas',
        to: '/admin/scheduler',
        acl: ['admin'],
      },

      {
        icon: 'mdi-rabbit',
        text: 'Broker',
        to: '/admin/broker',
        acl: ['admin'],
      },

      {
        icon: 'mdi-database-check',
        text: 'Central de Dados',
        to: '/admin/data-central',
        acl: ['admin'],
      },
    ],
  }),

  computed: {
    navItems: (vm) => {
      const userAcl = vm.$store.getters.user.acl;

      function checkItemAcl(item) {
        return !item.acl || (Array.isArray(item.acl)
          ? item.acl.includes(userAcl)
          : item.acl === userAcl);
      }

      function parseItems(itemArray) {
        return itemArray.filter(checkItemAcl).map(
          (item) => (Array.isArray(item.children) ? { ...item, children: parseItems(item.children) } : item),
        );
      }

      return parseItems(vm.rawItems);
    },
  },
};
</script>
