interface Message {
  icon: string,
  iconColor: string,
  status: string
  label: string,
  details: string,
  errorList: [],
}

export interface BulkAlertState {
  isVisible: boolean,

  icon: string,
  iconColor: string,
  isLoading: boolean,

  title: string,
  subtitle: string,
  data: Message[],
}

export default {
  isVisible: false,

  icon: '',
  iconColor: '',
  isLoading: false,

  title: '',
  subtitle: '',
  data: [],
} as BulkAlertState;
