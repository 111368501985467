import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store';

// Single Views
import Dashboard from './views/Dashboard.vue';
import Authentication from './views/Authentication.vue';

// "Module" routes
import admin from './routes/admin';
import administrators from './routes/administrators';
import companies from './routes/companies';
import funds from './routes/funds';
import holdings from './routes/holdings';
import housefunds from './routes/housefunds';
import indexes from './routes/indexes';
import liabilities from './routes/liabilities';
import managers from './routes/managers';
import operations from './routes/operations';
import quant from './routes/quant';
import quotaholders from './routes/quotaholders';
import quotaholdersoperations from './routes/quotaholdersoperations';
import returns from './modules/returns/routes';
import shareOptions from './routes/sharesOptions';
import shares from './routes/shares';
import updates from './routes/updates';
import user from './routes/user';

Vue.use(Router);

// Export Router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'login',
      path: '/login',
      alias: '/',
      component: Authentication,
      meta: {
        public: true,
      },
    },

    {
      name: 'home',
      path: '/dashboard',
      component: Dashboard,
    },

    ...admin,
    ...administrators,
    ...companies,
    ...funds,
    ...holdings,
    ...housefunds,
    ...indexes,
    ...liabilities,
    ...managers,
    ...operations,
    ...quant,
    ...quotaholders,
    ...quotaholdersoperations,
    ...returns,
    ...shareOptions,
    ...shares,
    ...updates,
    ...user,
  ],
});

// Authentication Middleware
router.beforeEach((to, from, next) => {
  if (!Store.getters.authenticated) {
    const tokenLocalStorage = window.localStorage.getItem('jwt');
    if (tokenLocalStorage) {
      Store.dispatch('login', { jwt: tokenLocalStorage });
    }
  }

  // If the route is public
  if (to.matched.some((record) => record.meta.public)) {
    if (Store.getters.authenticated) {
      next({ path: '/dashboard' });
    } else {
      next();
    }
  } else {
    // Private route
    if (!Store.getters.authenticated) {
      next({ path: '/login' });
      return;
    }

    next();
  }
});

export default router;
