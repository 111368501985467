import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'shares-list',
    path: '/shares',
    component: () => import(/* webpackChunkName: "shares" */ '../components/shares/SharesList.vue'),
  },

  {
    name: 'share-details',
    path: '/shares/:id',
    prop: true,
    component: () => import(/* webpackChunkName: "shares" */ '../components/shares/ShareById.vue'),
  },
] as RouteConfig[];
