import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'housefunds-management',
    path: '/housefunds',
    component: () => import(/* webpackChunkName: "housefunds" */ '../components/housefunds/HouseFundsManagementView.vue'),
  },

  {
    name: 'housefund-creation',
    path: '/housefunds/new',
    component: () => import(/* webpackChunkName: "housefunds" */ '../components/housefunds/HouseFundsCreateView.vue'),
  },

  {
    name: 'housefund-details',
    path: '/housefunds/:houseFundId',
    props: true,
    component: () => import(/* webpackChunkName: "housefunds" */ '../components/housefunds/HouseFundsDetailsView.vue'),
  },

  {
    name: 'housefund-edit',
    path: '/housefunds/:houseFundId/edit',
    props: true,
    component: () => import(/* webpackChunkName: "housefunds" */ '../components/housefunds/HouseFundsEditView.vue'),
  },
] as RouteConfig[];
