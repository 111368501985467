import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'quant-strategies-list',
    path: '/quant/strategies',
    component: () => import(/* webpackChunkName: "quant" */ '../components/quant/ListStrategies.vue'),
  },

  {
    name: 'quant-strategy-details',
    path: '/quant/strategies/:id',
    props: true,
    component: () => import(/* webpackChunkName: "quant" */ '../components/quant/Strategy.vue'),
  },

  {
    name: 'quant-overview',
    path: '/quant/overview',
    component: () => import(/* webpackChunkName: "quant" */ '../components/quant/Overview.vue'),
  },
] as RouteConfig[];
