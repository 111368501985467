import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'funds-list',
    path: '/funds',
    component: () => import(/* webpackChunkName: "funds" */ '../components/funds/FundsList.vue'),
  },

  {
    name: 'fund-details',
    path: '/funds/:id',
    props: true,
    component: () => import(/* webpackChunkName: "funds" */ '../components/funds/details/FundDetails.vue'),
  },
] as RouteConfig[];
