<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-icon v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>
        {{ text }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavTreeLeaf',

  inheritAttrs: false,

  props: {
    text: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
