<template>
  <v-list v-bind="$attrs" v-on="$listeners">
    <slot name="prepend" />

    <template v-for="(item, index) of items">
      <nav-tree-item :key="index" :config="item" />
    </template>

    <slot name="append" />
  </v-list>
</template>

<script>
import NavTreeItem from './NavTreeItem.vue';

export default {
  name: 'NavTreeRoot',

  inheritAttrs: false,

  components: {
    NavTreeItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
