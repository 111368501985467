import Vue from 'vue';
import LogRocket from 'logrocket';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './plugins/apexcharts';
import './plugins/clipboard';
import './plugins/maska';
import '@babel/polyfill';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

if (process.env.NODE_ENV === 'production') LogRocket.init('m058hu/w16');

// Vue configuration
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
