import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'managers-list',
    path: '/managers',
    component: () => import(/* webpackChunkName: "managers" */ '../components/managers/ManagersList.vue'),
  },

  {
    name: 'manager-details',
    path: '/managers/:id',
    props: true,
    component: () => import(/* webpackChunkName: "managers" */ '../components/managers/ManagerDetails.vue'),
  },
] as RouteConfig[];
