import { ActionTree } from 'vuex';
import { AlertState } from './state';

export default {
  showAlert({ commit }, options = {}) {
    commit('UPDATE_ALERT_ICON', options.icon ?? 'mdi-exclamation-thick');
    commit('UPDATE_ALERT_ICON_COLOR', options.iconColor ?? 'error');
    commit('UPDATE_ALERT_LOADING_STATE', options.loading ?? false);

    commit('UPDATE_ALERT_TITLE', options.title ?? 'Ooops!');
    commit('UPDATE_ALERT_MESSAGE', options.message ?? 'Algo de errado aconteceu, tente novamente mais tarde.');
    commit('UPDATE_ALERT_ERROR_LIST', options.errorList ?? []);

    commit('UPDATE_ALERT_VISIBILITY', true);
  },

  hideAlert({ commit }) {
    commit('UPDATE_ALERT_VISIBILITY', false);

    commit('UPDATE_ALERT_ICON', '');
    commit('UPDATE_ALERT_ICON_COLOR', '');
    commit('UPDATE_ALERT_LOADING_STATE', false);

    commit('UPDATE_ALERT_TITLE', '');
    commit('UPDATE_ALERT_MESSAGE', '');
    commit('UPDATE_ALERT_ERROR_LIST', []);
  },
} as ActionTree<AlertState, any>;
