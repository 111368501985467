import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'routines',
    path: '/admin/routines',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/routines/RoutinesView.vue'),
  },

  {
    name: 'scheduler',
    path: '/admin/scheduler',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/scheduler/SchedulerView.vue'),
  },

  {
    name: 'users-management',
    path: '/admin/users',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/users/UsersList.vue'),
  },

  {
    name: 'user-logs',
    path: '/admin/users-logs',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/users/UsersLogs.vue'),
  },

  {
    name: 'user-details',
    path: '/admin/users/:id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/users/UserDetails.vue'),
  },

  {
    name: 'broker',
    path: '/admin/broker',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/broker/Broker.vue'),
  },

  {
    name: 'data-central',
    path: '/admin/data-central',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/data/Overview.vue'),
  },
] as RouteConfig[];
