import { MutationTree } from 'vuex';
import { AlertState } from './state';

export default {
  UPDATE_ALERT_VISIBILITY(state, newValue) {
    state.isVisible = newValue;
  },

  UPDATE_ALERT_ICON(state, newValue) {
    state.icon = newValue;
  },

  UPDATE_ALERT_ICON_COLOR(state, newValue) {
    state.iconColor = newValue;
  },

  UPDATE_ALERT_LOADING_STATE(state, newValue) {
    state.isLoading = newValue;
  },

  UPDATE_ALERT_TITLE(state, newValue) {
    state.title = newValue;
  },

  UPDATE_ALERT_MESSAGE(state, newValue) {
    state.message = newValue;
  },

  UPDATE_ALERT_ERROR_LIST(state, newValue) {
    state.errorList = newValue;
  },
} as MutationTree<AlertState>;
