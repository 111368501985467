<template>
  <v-divider v-bind="config" v-if="isDivider" />

  <v-subheader v-bind="config" v-else-if="isSubheader">
    {{ config.text }}
  </v-subheader>

  <component :is="itemComponent" v-bind="config" v-else />
</template>

<script>
import NavTreeLeaf from './NavTreeLeaf.vue';
import NavTreeNode from './NavTreeNode.vue';

export default {
  name: 'NavTreeItem',

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isDivider: (vm) => !!vm.config.divider,
    isSubheader: (vm) => !!vm.config.subheader,
    hasChildren: (vm) => !!vm.config.children?.length,

    itemComponent: (vm) => (vm.hasChildren ? NavTreeNode : NavTreeLeaf),
  },
};
</script>
