interface RecurrentJob {
  job: (vm: any) => void;
  interval: number;
}

export default (jobList: RecurrentJob[]): any => ({
  mounted() {
    if (!Array.isArray(jobList)) {
      throw new Error('jobScheduler: "jobList" must be an array');
    }

    this.$_jobSchedulerIntervalIds = [];

    jobList.forEach(({ job, interval = 250 }) => {
      job.call(this, this);

      const intervalId = setInterval(job.bind(this, this), interval);
      this.$_jobSchedulerIntervalIds.push(intervalId);
    });
  },

  beforeDestroy() {
    if (Array.isArray(this.$_jobSchedulerIntervalIds)) {
      this.$_jobSchedulerIntervalIds.forEach(clearInterval);
    }
  },
});
