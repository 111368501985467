import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'updates-list',
    path: '/updates',
    component: () => import(/* webpackChunkName: "updates" */ '../components/updates/UpdatesList.vue'),
  },

  {
    name: 'update-details',
    path: '/updates/:id',
    props: true,
    component: () => import(/* webpackChunkName: "updates" */ '../components/updates/UpdateDetail.vue'),
  },
] as RouteConfig[];
