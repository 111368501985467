import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'operations-shares',
    path: '/operations/shares',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/SharesOperations.vue'),
  },

  {
    name: 'operations-shareoptions',
    path: '/operations/shareoptions',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/ShareOptionsOperations.vue'),
  },

  {
    name: 'operations-futures',
    path: '/operations/futures',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/FuturesOperations.vue'),
  },

  {
    name: 'operations-futureoptions',
    path: '/operations/futureoptions',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/FutureOptionsOperations.vue'),
  },

  {
    name: 'operations-bonds',
    path: '/operations/bonds',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/BondsOperations.vue'),
  },

  {
    name: 'operations-repurchase',
    path: '/operations/repurchase',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/RepurchaseOperations.vue'),
  },

  {
    name: 'operations-funds',
    path: '/operations/funds',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/FundsOperations.vue'),
  },

  {
    name: 'operations-loan',
    path: '/operations/loan',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/LoanOperations.vue'),
  },

  {
    name: 'operations-cashsettlements',
    path: '/operations/cashsettlements',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/CashSettlements.vue'),
  },

  {
    name: 'operations-cashsettlementsstatements',
    path: '/operations/cashsettlementsstatements',
    component: () => import(/* webpackChunkName: "operations" */ '../components/operations/CashSettlementsStatements.vue'),
  },
] as RouteConfig[];
