import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'administrators-list',
    path: '/administrators',
    component: () => import(/* webpackChunkName: "administrators" */ '../components/administrators/AdministratorsList.vue'),
  },

  {
    name: 'administrator-details',
    path: '/administrators/:id',
    props: true,
    component: () => import(/* webpackChunkName: "administrators" */ '../components/administrators/AdministratorsDetails.vue'),
  },
] as RouteConfig[];
