import { RouteConfig } from 'vue-router';
import { STORAGE_KEYS } from './config';

export default [
  {
    path: '/returns',
    beforeEnter(to, from, next) {
      const favBoardId = localStorage.getItem(STORAGE_KEYS.favBoard);

      next(favBoardId ? `/returns/${favBoardId}` : '/returns/management');
    },
  },

  {
    path: '/returns/management',
    component: () => import(/* webpackChunkName: "returns" */ './views/ReturnsBoardManagementView.vue'),
    meta: {
      breadcrumbs: [
        { text: 'Quadro de retornos' },
      ],
    },
  },

  {
    path: '/returns/:boardId',
    props: true,
    component: () => import(/* webpackChunkName: "returns" */ './views/ReturnsBoardDetailsView.vue'),
    meta: {
      breadcrumbs: [
        { text: 'Quadro de retornos', to: '/returns/management', exact: true },
        { text: 'Detalhes' },
      ],
    },
  },
] as RouteConfig[];
