import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'liabilities-management',
    path: '/liabilities',
    component: () => import(/* webpackChunkName: "liabilities" */ '../components/liabilities/LiabilitiesManagementView.vue'),
  },

  {
    name: 'liabilities-details',
    path: '/liabilities/:houseFundId',
    props: true,
    component: () => import(/* webpackChunkName: "liabilities" */ '../components/liabilities/LiabilitiesDetailsView.vue'),
  },
] as RouteConfig[];
