import { MutationTree } from 'vuex';
import { BulkAlertState } from './state';

export default {
  UPDATE_BULK_ALERT_VISIBILITY(state, newValue) {
    state.isVisible = newValue;
  },

  UPDATE_BULK_ALERT_ICON(state, newValue) {
    state.icon = newValue;
  },

  UPDATE_BULK_ALERT_ICON_COLOR(state, newValue) {
    state.iconColor = newValue;
  },

  UPDATE_BULK_ALERT_LOADING_STATE(state, newValue) {
    state.isLoading = newValue;
  },

  UPDATE_BULK_ALERT_TITLE(state, newValue) {
    state.title = newValue;
  },

  UPDATE_BULK_ALERT_SUBTITLE(state, newValue) {
    state.subtitle = newValue;
  },

  UPDATE_BULK_ALERT_DATA(state, newValue) {
    state.data = newValue;
  },

} as MutationTree<BulkAlertState>;
