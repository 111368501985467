<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"

    :value="value"
    :max-width="maxWidth"
  >
    <v-card tile class="text-center">
      <v-system-bar class="justify-end">
        <v-icon @click.stop="() => $emit('input', false)">
          mdi-close
        </v-icon>
      </v-system-bar>

      <div class="pt-6">
        <v-progress-circular
          :indeterminate="loading"
          :color="iconColor"
          :value="loading ? 0 : 100"
          size="80"
        >
          <v-icon x-large :color="iconColor" v-if="icon">
            {{ icon }}
          </v-icon>
        </v-progress-circular>
      </div>

      <v-card-title class="justify-center mb-2" v-if="title">
        {{ title }}
      </v-card-title>

      <v-card-subtitle v-if="message">
        {{ message }}
      </v-card-subtitle>

      <slot />

      <slot name="error-details">
        <v-expand-transition>
          <div v-if="errorList.length > 0">
            <v-divider class="mx-4" />

            <v-card-text class="text-left">
              <template v-for="(error, index) of errorList">
                <p :key="index" class="text-body-2 my-2 px-4">
                  <v-icon color="error" left>
                    mdi-alert-circle-outline
                  </v-icon>

                  <span style="vertical-align: middle">
                    {{ error }}
                  </span>
                </p>
              </template>
            </v-card-text>
          </div>
        </v-expand-transition>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ContextualDialog',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [String, Number],
      default: '400',
    },

    icon: {
      type: String,
      default: '',
    },

    iconColor: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    errorList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
