<template>
  <v-chip :color="roleProps.color" :text-color="roleProps.textColor" v-bind="$attrs" v-on="$listeners">
    <v-icon left :small="$attrs.small">
      {{ roleProps.icon }}
    </v-icon>

    {{ roleProps.text }}
  </v-chip>
</template>

<script>
const ROLE_SETTINGS = {
  user: {
    text: 'Usuário',
    color: 'blue',
    textColor: 'white',
    icon: 'mdi-account',
  },

  admin: {
    text: 'Admin',
    color: 'orange',
    textColor: 'white',
    icon: 'mdi-account-tie',
  },

  quant: {
    text: 'Quant',
    color: 'purple',
    textColor: 'white',
    icon: 'mdi-atom-variant',
  },

  backoffice: {
    text: 'Backoffice',
    color: 'blue-grey',
    textColor: 'white',
    icon: 'mdi-tools',
  },
};

export default {
  name: 'UserRoleChip',

  props: {
    role: {
      type: String,
      default: 'user',
    },
  },

  computed: {
    roleProps: (vm) => ROLE_SETTINGS[vm.role] || ROLE_SETTINGS.user,
  },
};
</script>
