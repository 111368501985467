<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"

    :value="value"
    :max-width="maxWidth"
  >
    <v-card tile class="text-center">
      <v-system-bar class="justify-end">
        <v-icon @click.stop="() => $emit('input', false)">
          mdi-close
        </v-icon>
      </v-system-bar>

      <div class="pt-6">
        <v-progress-circular
          :indeterminate="loading"
          :color="iconColor"
          :value="loading ? 0 : 100"
          size="80"
        >
          <v-icon dense x-large :color="iconColor" v-if="icon">
            {{ icon }}
          </v-icon>
        </v-progress-circular>
      </div>

      <v-card-title class="justify-center mb-2" v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-subtitle class="justify-center mb-2" v-if="subtitle">
        {{ subtitle }}
      </v-card-subtitle>

      <div class="pa-2">
        <v-expansion-panels accordion v-if="data">
          <v-expansion-panel
            v-for="(item, i) in data"
            :key="i"
          >
            <v-expansion-panel-header class="text-elipsis text-body-2 py-0">
              <div>
                <v-icon flex :color="item.iconColor">
                  {{ item.icon }}
                </v-icon>
                {{ item.label }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-subtitle-2">
              <v-chip
                small
                label
                class="mr-4 text-center"
                :color="item.iconColor"
              >
                {{ item.status }}
              </v-chip>

              <span>{{ item.details }}</span>

              <v-menu
                top
                max-height="400"
                :open-on-hover="true"
                :close-on-content-click="false"
                v-if="item.errorList.length"
              >
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small>mdi-information</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default>
                  <v-card>
                    <v-card-text class="primary--text">
                      <pre>{{ item.errorList }}</pre>
                    </v-card-text>
                  </v-card>
                </template>
              </v-menu>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ContextualDialogBulk',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [String, Number],
      default: '700',
    },

    icon: {
      type: String,
      default: '',
    },

    iconColor: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
