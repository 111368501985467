import { ActionTree } from 'vuex';
import api from '@/services/api';
import { HouseFundState } from './state';

export default {
  async fetchList({ commit }) {
    commit('INCREMENT_LOADING_COUNT');

    try {
      const { data } = await api.houseFunds.getHouseFundsList();

      commit('UPDATE_RESULTS', data.results);
      commit('UPDATE_RESPONSE_DATE', new Date());
      commit('SET_REQUEST_ERROR', null);
    } catch (error) {
      commit('SET_REQUEST_ERROR', error);
    } finally {
      commit('DECREMENT_LOADING_COUNT');
    }
  },
} as ActionTree<HouseFundState, any>;
