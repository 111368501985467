<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer v-model="drawer" v-if="authenticated" clipped app>
        <Sidebar />
      </v-navigation-drawer>

      <Navigation :drawer="drawer" @toggle-drawer="setDrawer" app />

      <v-main>
        <v-fade-transition mode="in-out">
          <v-breadcrumbs :items="breadcrumbItems" v-if="shouldShowBreadcrumbs" />
        </v-fade-transition>

        <v-slide-x-transition mode="out-in">
          <router-view />
        </v-slide-x-transition>
      </v-main>

      <v-snackbar :timeout="-1" v-model="notification.status" :color="notification.color">
        {{ notification.text }}
        <v-btn text @click="notification.status = false">Fechar</v-btn>
      </v-snackbar>

      <app-alert />
      <app-bulk-alert />
    </v-app>
  </div>
</template>

<style>
body,
.theme--light.v-application {
  background: #ecf0f5 !important;
}
</style>

<script>
import { axios } from '@/services/api';
import Store from '@/store';

import AppAlert from '@/components/global/AppAlert.vue';
import AppBulkAlert from '@/components/global/AppAlertBulk.vue';
import Navigation from '@/components/navigation/Navigation.vue';
import Sidebar from '@/components/navigation/Sidebar.vue';

export default {
  data: () => ({
    drawer: !(window.innerWidth < 600),
    mini: true,
  }),

  components: {
    AppAlert,
    AppBulkAlert,
    Navigation,
    Sidebar,
  },

  computed: {
    authenticated: (vm) => vm.$store.getters.authenticated,
    notification: (vm) => vm.$store.state.notification,

    routeBreadcrumbs: (vm) => vm.$route.matched.reduce((items, route) => (
      route.meta.breadcrumbs ? [...items, ...route.meta.breadcrumbs] : items
    ), []),

    breadcrumbItems: (vm) => [
      { to: '/', text: 'Home', exact: true },
      ...vm.routeBreadcrumbs,
    ],

    shouldShowBreadcrumbs: (vm) => vm.routeBreadcrumbs.length > 0,
  },

  methods: {
    setDrawer(status) {
      this.drawer = status;
    },

    addApiInterceptor() {
      // 403 interceptor error
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 403) {
            Store.dispatch('logout', { redirectUrl: this.$route.fullPath });
            this.$router.push('/login?error=tokenExpired');
            return Promise.reject(error);
          }

          return Promise.reject(error);
        },
      );
    },
  },

  created() {
    const tokenLocalStorage = window.localStorage.getItem('jwt');
    const { token } = this.$route.query;
    const finalToken = token || tokenLocalStorage;

    if (finalToken) {
      Store.dispatch('login', { jwt: finalToken });

      this.addApiInterceptor();

      if (this.$route.path === '/login') {
        const redirectUrl = window.localStorage.getItem('redirect-url') || '/dashboard';
        window.localStorage.removeItem('redirect-url');
        this.$router.push(redirectUrl);
      }
    }
  },
};
</script>
