<template>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg3>

            <v-card class="elevation-6">
              <div style="background: #18324f" class="text-center py-3">
                  <img alt="Loyall" height="60px" src="@/assets/logo_capital_partners.svg">
              </div>
              <v-card-text v-if="errorCode">
                <v-alert class="mb-0" type="error">
                  {{errorMessage}}
                </v-alert>
              </v-card-text>
          <v-container class="pt-4 pb-4 pl-3 pr-3">
            <div class="text-center mb-3">
              <span class="text-body-2">Entre com a sua conta Google</span>
            </div>
            <v-btn :href="loginUrl" block color="primary">
              <v-icon class="mr-3">mdi-google</v-icon>Google
            </v-btn>
          </v-container>
            </v-card>
              <v-flex class="pl-3 pr-3 pb-3 pt-1 mt-4">
              <div class="text-center mb-3">
                <span class="text-body-2"><a href="https://loyall.com.br" target="_blank">www.loyall.com.br</a></span>
              </div>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      errorCode: null,
      errorMessage: '',
      loginUrl: api.auth.google(),
    };
  },
  created() {
    const {
      error,
    } = this.$route.query;

    if (error) {
      this.errorCode = error;
      switch (this.errorCode) {
        case 'tokenExpired':
          this.errorMessage = 'Sua sessão expirou! Faça login novamente.';
          break;
        case '1300':
          this.errorMessage = 'Domínio não permitido!';
          break;
        default:
          this.errorMessage = 'Por favor contate o administrador.';
          break;
      }
    }
  },
};
</script>
