import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'shareoptions-list',
    path: '/shareoptions',
    component: () => import(/* webpackChunkName: "shareoptions" */ '../components/shareoptions/ShareOptionsList.vue'),
  },

  {
    name: 'shareoptions-details',
    path: '/shareoptions/:id',
    prop: true,
    component: () => import(/* webpackChunkName: "shareoptions" */ '../components/shareoptions/ShareOptionById.vue'),
  },
] as RouteConfig[];
