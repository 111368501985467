<template>
  <contextual-dialog-bulk
    v-bind="dialogOptions"

    :value="isAlertVisible"
    @input="handleVisibilityChange"
  />
</template>

<script>
import ContextualDialogBulk from './ContextualDialogBulk.vue';

export default {
  name: 'AppAlertBulk',

  components: {
    ContextualDialogBulk,
  },

  computed: {
    isAlertVisible: (vm) => vm.$store.state.bulkAlert?.isVisible,

    dialogOptions: (vm) => ({
      loading: vm.$store.state.bulkAlert?.isLoading,
      icon: vm.$store.state.bulkAlert?.icon,
      iconColor: vm.$store.state.bulkAlert?.iconColor,
      title: vm.$store.state.bulkAlert?.title,
      subtitle: vm.$store.state.bulkAlert?.subtitle,
      data: vm.$store.state.bulkAlert?.data,
    }),
  },

  methods: {
    handleVisibilityChange(newState) {
      if (!newState) {
        this.$store.dispatch('bulkAlert/hideBulkAlert');
      }
    },
  },
};
</script>
