<template>
  <div>
    <!-- Main navigation -->
    <v-app-bar
      dark
      app
      fixed
      clipped-left
      color="secondary"
      v-if="isAuthenticated">
      <v-app-bar-nav-icon @click.prevent="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/dashboard">
          <img
            style="display: block;"
            height="45px"
            alt="Loyall"
            src="@/assets/logo_capital_partners.svg"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu class="ml-2" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
        v-on="on"
        class="mr-1"
        icon>
          <v-avatar
              size="36px"
            >
              <img
                v-if="user.avatar"
                :alt="user.name"
                :src="user.avatar"
              >
              <v-icon
                v-else
              >mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <p class="text-subtitle-1 font-weight-bold text-no-wrap text-truncate ma-0">
              {{user.name}}
            </p>

            <p class="text-body-2 mb-0">{{user.email}}</p>
          </v-card-text>

          <v-divider />

          <v-card-actions class="justify-space-between">
            <user-role-chip :role="user.acl" small to="/me" />

            <v-tooltip v-model="showUserMenu" bottom right>
              <template v-slot:activator="{ on }">
                <v-btn @click.prevent="logOut"  v-on="on" right small text>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
              </template>

              <template v-slot:default>
                <span>Sair</span>
              </template>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<style lang="scss">
  .v-navigation-drawer .v-list__tile--active {
    background: #18324f24 !important;
  }
  .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
  }
  // .v-navigation-drawer .v-toolbar__title {
  //   overflow: hidden;
  //   width: 42px;
  // }
  // .v-navigation-drawer--is-mouseover {
  //   .v-toolbar__title {
  //     overflow: hidden;
  //     width: auto;
  //   }
  // }
</style>

<script>
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

import UserRoleChip from '@/components/global/UserRoleChip.vue';

export default {
  name: 'Navigation',
  props: ['drawer'],
  components: {
    UserRoleChip,
  },

  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  data: () => ({
    appTitle: 'Meal Prep',
    toggle: false,
    isLoading: false,
    showUserMenu: false,
    items: [],
    search: '',
    notifications: [],
  }),

  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async getData() {
      if (!this.$store.getters.authenticated) {
        return;
      }

      const { data: { notifications: { history } } } = await api.me.whoami();

      this.notifications = history;
    },

    toggleDrawer() {
      this.$emit('toggle-drawer', !this.drawer);
    },

    logOut() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
