export interface AlertState {
  isVisible: boolean,

  icon: string,
  iconColor: string,
  isLoading: boolean,

  title: string,
  message: string,
  errorList: string[],
}

export default {
  isVisible: false,

  icon: '',
  iconColor: '',
  isLoading: false,

  title: '',
  message: '',
  errorList: [],
} as AlertState;
