import { MutationTree } from 'vuex';
import { HouseFundState } from './state';

export default {
  // Results
  UPDATE_RESULTS(state, newData) {
    state.results = newData;
  },

  // Loading count
  INCREMENT_LOADING_COUNT(state) {
    state.loadingCount += 1;
  },

  DECREMENT_LOADING_COUNT(state) {
    if (state.loadingCount <= 0) {
      return;
    }

    state.loadingCount -= 1;
  },

  // Last response date
  UPDATE_RESPONSE_DATE(state, newData) {
    state.lastResponseDate = newData;
  },

  // Request error
  SET_REQUEST_ERROR(state, newData) {
    state.requestError = newData;
  },
} as MutationTree<HouseFundState>;
