





















import Vue from 'vue';

export default Vue.extend({
  name: 'home',

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
});
