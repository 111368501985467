import { RouteConfig } from 'vue-router';

export default [
  {
    name: 'holdings-management',
    path: '/holdings',
    component: () => import(/* webpackChunkName: "holdings" */ '../components/holdings/HoldingsManagementView.vue'),
  },

  {
    name: 'holding-details',
    path: '/holdings/:houseFundId',
    component: () => import(/* webpackChunkName: "holdings" */ '../components/holdings/HoldingsDetailsView.vue'),
    props: true,
  },
] as RouteConfig[];
