import Vue from 'vue';
import Vuex from 'vuex';
import jsonwebtoken from 'jsonwebtoken';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

import { axios } from '@/services/api';

import alert from './alert';
import bulkAlert from './bulk-alert';
import houseFunds from './house-funds';

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jwt: window.localStorage.getItem('jwt') || '',
    user: {},
    authenticated: false,
    notification: {
      status: false,
      text: '',
      color: '',
    },
  },
  mutations: {
    setIsAuthenticated(state, payload) {
      state.authenticated = payload;
    },
    setUser(state, user) {
      state.user = user;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    unsetNotification(state) {
      state.notification.status = false;
    },
  },

  actions: {
    login({ commit }, { jwt }) {
      type User = Record<string, any> | null;
      const user = jsonwebtoken.decode(jwt) as User;

      if (!user) {
        this.dispatch('logout');
        return;
      }

      commit('setUser', user);
      commit('setJwt', jwt);
      commit('setIsAuthenticated', true);

      window.localStorage.setItem('jwt', jwt);
      axios.defaults.headers.common.Authorization = `Bearer ${this.getters.jwt}`;

      if (process.env.NODE_ENV === 'production') {
        LogRocket.identify(user.id, user);
      }
    },

    logout({ commit }, { redirectUrl = '' } = {}) {
      commit('setIsAuthenticated', false);

      window.localStorage.removeItem('jwt');
      delete axios.defaults.headers.common.Authorization;

      if (redirectUrl) {
        window.localStorage.setItem('redirect-url', redirectUrl);
      }
    },

    notification({ commit }, notification) {
      commit('setNotification', notification);
    },
  },
  getters: {
    jwt(state) {
      return state.jwt;
    },
    user(state) {
      return state.user;
    },
    authenticated(state) {
      return state.authenticated;
    },
  },

  modules: {
    alert,
    bulkAlert,
    houseFunds,
  },

  plugins: [logrocketPlugin],
});
